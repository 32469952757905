// src/AboutUs.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

const BackgroundAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #c9d6ff, #e2e2e2, #f8f9fa, #ffffff);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 15s ease infinite;
`;

const ContentContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: left;
  animation: ${fadeIn} 1s ease-out;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #333;
  transition: color 0.3s;
  &:hover {
    color: #007bff;
  }
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  transition: transform 0.3s, color 0.3s;
  &:hover {
    transform: translateX(10px);
    color: #007bff;
  }
`;

const AboutUs = () => (
  <StyledContainer fluid>
    <ContentContainer>
      <Heading>About Us</Heading>
      <Row className="mb-4">
        <Col>
          <Text>
            A V Apparao's Prajna Legal Services is all about "LAW". It provides consultation, family counseling, LSR or Bank Opinions and more.
          </Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text>
            A V Apparao's Shree Manidweepeshwari Jaathaka Darshini is the astrology service. Mr. A V Apparao is one of the best astrologists all over India.
          </Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text>
            The Chairman and Managing Directors are Mr. A.V. Appa Rao and Mrs. Anantha Lakshmi.
          </Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text>
            Mr. A.V. Appa Rao has many degrees like M.Com., LL.M., PGDCL & IPR. He started his legal career in 1999 and is still continuing. He is very experienced, having worked in a reputed MNC for nearly 12 years, winning many awards. After resigning, he ventured into private practice, solving many cases and providing expert opinions and LSRs. He started this Law Firm in 2004, initially called VSCS, now known as Prajna Legal Services. He has also authored a book on law and resolved numerous legal issues.
          </Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text>
            Mrs. Anantha Lakshmi holds an MA in Hindi. She is an experienced teacher. She excels in marketing and counseling.
          </Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text>
            This firm offers a wide range of services. To learn more about the services provided, visit the respective services tab.
          </Text>
        </Col>
      </Row>
    </ContentContainer>
  </StyledContainer>
);

export default AboutUs;
