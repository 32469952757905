import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import './Footer.css'; // Import the CSS file

const Footer = () => (
  <footer className="footer">
    <Container>
      <Row className="justify-content-center">
        <Col md="auto">
          <a href="https://X.com/@Prajnalegal" target="_blank" rel="noopener noreferrer">
            <FaTwitter size={30} />
          </a>
          <a href="https://instagram.com/prajnalegal" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={30} />
          </a>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="auto">
          <p>© 2024 A V Apparao Services</p>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col md="auto">
          <ul className="list-unstyled">
            {/* Add more footer links as needed */}
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
