import React, { useState, useEffect } from 'react';
import { auth, storage, ref, getDownloadURL, uploadBytes } from './firebase'; // Update imports
import { getFirestore, collection, query, where, onSnapshot, deleteDoc, doc, getDocs } from 'firebase/firestore'; // Update imports
import { Container, Card, ListGroup, Button, Modal, Form } from 'react-bootstrap';
import styled, { keyframes, css } from 'styled-components';
import defaultProfilePic from './default-profile-pic.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the useAuth hook

// Styled components and keyframes for animations
const BackgroundAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffe5b4, #ffcc99); /* Peach-yellow gradient */
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  transition: opacity 1s ease-in-out;
  width: 100%;
  ${({ isFadingOut }) =>
    isFadingOut &&
    css`
      animation: ${fadeOut} 1s forwards;
    `}
`;

const AvatarContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 3px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.1);
  }
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ChangeProfileText = styled.p`
  font-size: 14px;
  color: #00000;
  text-align: center;
  margin-top: 0.5rem;
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const AppointmentsCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const LogoutButton = styled(Button)`
  margin-bottom: 2rem;
  background-color: #ff6f61;
  border: none;
  &:hover {
    background-color: #ff3d2e;
  }
`;

const ChangePasswordButton = styled(Button)`
  margin-bottom: 2rem;
  margin-left: 1rem;
  background-color: #3cb371;
  border: none;
  &:hover {
    background-color: #2e8b57;
  }
`;

const LogoutMessage = styled.div`
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  margin-top: 2rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const UserDetailsCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const UserDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
`;

const UserDetailsTitle = styled.h3`
  margin: 0;
`;

const UserDetailsContent = styled.div`
  padding: 10px;
  display: ${({ minimized }) => (minimized ? 'none' : 'block')};
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [cancelAppointmentId, setCancelAppointmentId] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);

        // Initialize Firestore
        const firestore = getFirestore();

        // Query appointments for the current user
        const q = query(collection(firestore, 'appointments'), where('email', '==', user.email));
        const unsubscribeSnapshot = onSnapshot(q, (querySnapshot) => {
          const appointmentsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setAppointments(appointmentsData);
        });

        // Load user profile picture if exists
        const storageRef = ref(storage, `users/${user.uid}/profile.jpg`);
        getDownloadURL(storageRef)
          .then((url) => setProfilePicUrl(url))
          .catch(() => setProfilePicUrl(defaultProfilePic));

        return () => unsubscribeSnapshot();
      } else {
        setUser(null);
      }
    });

    // Cleanup function to delete expired appointments after 40 days
    const cleanupExpiredAppointments = async () => {
      const firestore = getFirestore();
      const appointmentsRef = collection(firestore, 'appointments');
      const q = query(
        appointmentsRef,
        where('createdAt', '<', new Date(Date.now() - 40 * 24 * 60 * 60 * 1000))
      );
      const querySnapshot = await getDocs(q);

      const deletePromises = querySnapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref);
      });

      await Promise.all(deletePromises);
    };

    // Run cleanup function initially and then every 24 hours
    cleanupExpiredAppointments();
    const interval = setInterval(cleanupExpiredAppointments, 24 * 60 * 60 * 1000);

    return () => {
      clearInterval(interval);
      unsubscribeAuth();
    };
  }, []);

  const handleDeleteAppointment = (id) => {
    // Show confirmation before setting cancelAppointmentId
    const confirmed = window.confirm('To cancel your appointment, please email us at avapparao.co@gmail.com');
    if (confirmed) {
      setCancelAppointmentId(id);
    }
  };

  const handleCancelAppointment = async () => {
    try {
      if (cancelAppointmentId) {
        // Perform cancel operation here if needed
        console.log('Canceled appointment with ID:', cancelAppointmentId);
        // Clear cancelAppointmentId after operation
        setCancelAppointmentId(null);
      }
    } catch (error) {
      console.error('Error canceling appointment:', error);
    }
  };

  const handleLogout = async () => {
    try {
      setShowLogoutMessage(true);
      setIsFadingOut(true);
      setTimeout(async () => {
        await auth.signOut();
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleProfilePicClick = () => {
    setShowModal(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewProfilePic(file);
  };

  const handleUploadProfilePic = async () => {
    if (newProfilePic) {
      const storageRef = ref(storage, `users/${user.uid}/profile.jpg`);
      try {
        await uploadBytes(storageRef, newProfilePic);
        const url = await getDownloadURL(storageRef);
        setProfilePicUrl(url);
        setShowModal(false);
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  };

  const toggleUserDetails = (id) => {
    if (selectedUserDetails !== id) {
      setSelectedUserDetails(id); // Expand the selected one
    } else {
      setSelectedUserDetails(null); // Collapse if already expanded
    }
  };

  const handleOpenChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  const handleChangePassword = () => {
    // Implement your logic to handle password change request
    alert('To change password, email us with your UID to avapparao.co@gmail.com');
    handleCloseChangePasswordModal();
  };

  return (
    <StyledContainer isFadingOut={isFadingOut}>
      {user ? (
        <>
          <ProfileSection>
            <AvatarContainer onClick={handleProfilePicClick}>
              <Avatar src={profilePicUrl || defaultProfilePic} alt="Profile" />
            </AvatarContainer>
            <ChangeProfileText>To Change The Profile Picture, Either Click On The Profile Pic Or Press The Edit Profile Pic Button Below!!</ChangeProfileText>
            <ChangeProfileText>Scoll Down To View Your Appointments.</ChangeProfileText>
            <h1>Welcome, {user.displayName || user.email}!</h1>
          </ProfileSection>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          <ChangePasswordButton onClick={handleOpenChangePasswordModal}>Change Password</ChangePasswordButton>
          <UserDetailsCard onClick={() => toggleUserDetails('userDetails')}>
            <UserDetailsHeader>
              <UserDetailsTitle>Personal Details</UserDetailsTitle>
              <Button variant="primary" onClick={handleProfilePicClick}>
                Edit Profile Picture
              </Button>
            </UserDetailsHeader>
            <UserDetailsContent minimized={false}>
              <ListGroup>
                <ListGroup.Item>Email: {user.email}</ListGroup.Item>
                <ListGroup.Item>UID: {user.uid}</ListGroup.Item>
                <ListGroup.Item>Creation Time: {user.metadata.creationTime}</ListGroup.Item>
                <ListGroup.Item>Last Sign-In Time: {user.metadata.lastSignInTime}</ListGroup.Item>
              </ListGroup>
            </UserDetailsContent>
          </UserDetailsCard>

          <AppointmentsCard>
            <Card.Header>
              <h3>Appointments</h3>
            </Card.Header>
            <ListGroup variant="flush">
              {appointments.length === 0 ? (
                <ListGroup.Item>No appointments found.</ListGroup.Item>
              ) : (
                appointments.map((appointment) => (
                  <ListGroup.Item key={appointment.id}>
                    <div>
                      <strong>Appointment ID:</strong> {appointment.id}
                    </div>
                    <div>
                      <strong>Name:</strong> {appointment.name}
                    </div>
                    <div>
                      <strong>Email:</strong> {appointment.email}
                    </div>
                    <div>
                      <strong>Phone Number:</strong> {appointment.phone}
                    </div>
                    <div>
                      <strong>Service:</strong> {appointment.serviceType}
                    </div>
                    <div>
                      <strong>Sub Category:</strong> {appointment.subCategory}
                    </div>
                    <div>
                      <strong>Date:</strong> {appointment.date}
                    </div>
                    <div>
                      <strong>Time:</strong> {appointment.time}
                    </div>
                    <div>
                      <strong>Status:</strong> {appointment.status}
                    </div>
                    <Button variant="danger" onClick={() => handleDeleteAppointment(appointment.id)}>
                      Cancel Appointment
                    </Button>
                  </ListGroup.Item>
                ))
              )}
            </ListGroup>
          </AppointmentsCard>
          <LogoutMessage show={showLogoutMessage}>Signing you out...</LogoutMessage>

          {/* Modal for profile picture upload */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Change Profile Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formProfilePic">
                  <Form.Label>Select a profile picture</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleUploadProfilePic}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for changing password */}
          <Modal show={showChangePasswordModal} onHide={handleCloseChangePasswordModal}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                To change your password, please email us with your UID at{' '}
                <a>avapparao.co@gmail.com</a>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseChangePasswordModal}>
                Close
              </Button>
              
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </StyledContainer>
  );
};

export default Dashboard;
