// src/Contact.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaEnvelope } from 'react-icons/fa';
import { Container } from 'react-bootstrap';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s ease-out, ${gradientAnimation} 15s ease infinite;
  background: linear-gradient(270deg, #c9d6ff, #e2e2e2, #f8f9fa, #ffffff);
  background-size: 400% 400%;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`;

const Contact = () => (
  <StyledContainer fluid>
    <FormContainer>
      <Heading>
        <FaEnvelope /> Contact Us
      </Heading>
      <StyledIframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLScZIx-yAEZ6HvrP1L9YJD_cgXq9iRDsopyWUDo5CKWHM_eGGw/viewform?embedded=true" 
        title="Contact Form"
      >
        Loading…
      </StyledIframe>
    </FormContainer>
  </StyledContainer>
);

export default Contact;
