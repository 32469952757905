import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider, useAuth } from './AuthContext';
import HomePage from './HomePage';
import AboutUs from './AboutUs';
import Contact from './Contact';
import SignUp from './SignUp';
import Login from './Login';
import Dashboard from './Dashboard';
import AppointmentForm from './AppointmentForm';
import AdminDashboard from './AdminDashboard';
import PrivateRoute from './PrivateRoute';
import AdminLogin from './AdminLogin';
import Footer from './Footer';
import LegalServicesPage from './LegalServicesPage';
import AstrologyServicesPage from './AstrologyServicesPage';
import { Analytics } from "@vercel/analytics/react"

const App = () => {
  const { currentUser, isAdmin } = useAuth();

  if (currentUser === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg" className="px-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <Navbar.Brand as={Link} to="/" style={{ fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold' }}>A V Apparao Services</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ marginLeft: 'auto' }}>
            <Nav.Link as={Link} to="/" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/contact" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Contact</Nav.Link>
            <Nav.Link as={Link} to="/legal-services" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Legal Services</Nav.Link>
            <Nav.Link as={Link} to="/astrology-services" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Astrology Services</Nav.Link>
            {currentUser ? (
              <>
                {isAdmin ? (
                  <Nav.Link as={Link} to="/admin/dashboard" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Admin Dashboard</Nav.Link>
                ) : (
                  <>
                    <Nav.Link as={Link} to="/dashboard" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Profile</Nav.Link>
                    <Nav.Link as={Link} to="/appointment" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Book Appointment</Nav.Link>
                  </>
                )}
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/signup" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Sign Up</Nav.Link>
                <Nav.Link as={Link} to="/login" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Login</Nav.Link>
                <Nav.Link as={Link} to="/appointment" style={{ fontFamily: 'Arial', fontSize: '16px', margin: '0 10px', color: 'white' }}>Book Appointment</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/appointment" element={<AppointmentForm />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/admin/dashboard" element={<PrivateRoute adminOnly><AdminDashboard /></PrivateRoute>} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/legal-services" element={<LegalServicesPage />} />
        <Route path="/astrology-services" element={<AstrologyServicesPage />} />
       </Routes>
      <Footer />
      <Analytics/>
    </Router>
  );
};

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
