// src/Login.js
import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FaLock } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth hook

const BackgroundAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #89f7fe, #66a6ff, #89f7fe, #66a6ff);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 15s ease infinite;
`;

const FormWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const SuccessScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  animation: ${keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `} 0.5s ease;
`;

const SuccessText = styled.h3`
  color: #4caf50;
`;

const SignupLink = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #007bff;
  text-align: center;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Get currentUser from AuthContext
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard'); // Redirect to dashboard if user is already logged in
    }
  }, [currentUser, navigate]);

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    } catch (error) {
      alert(error.message);
    }
    setSubmitting(false);
  };

  return (
    <StyledContainer fluid>
      <FormWrapper>
        <IconWrapper>
          <FaLock />
        </IconWrapper>
        <h2>Login</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().required('Required')
          })}
          onSubmit={handleLogin}
        >
          <FormikForm>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage name="email" component={ErrorText} />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component={ErrorText} />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Login
            </Button>
          </FormikForm>
        </Formik>
        <SignupLink>
          Don't have an account? <Link to="/signup">Sign up here</Link>
        </SignupLink>
      </FormWrapper>
      {showSuccess && (
        <SuccessScreen>
          <SuccessText>Login Successful!</SuccessText>
          <p>Redirecting to profile...</p>
        </SuccessScreen>
      )}
    </StyledContainer>
  );
};

export default Login;
