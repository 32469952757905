// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAjBLuJV-NIrr96jpH8GcjoqjhrOVn39nw",
    authDomain: "avaparao.firebaseapp.com",
    projectId: "avaparao",
    storageBucket: "avaparao.appspot.com",
    messagingSenderId: "543505476278",
    appId: "1:543505476278:web:cb78362665e77513d45417",
    measurementId: "G-VLHB0B3WGV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, firestore, storage, ref, getDownloadURL, uploadBytes };
