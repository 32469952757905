// src/SignUp.js
import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth } from './firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'; // Import updateProfile
import { FaLock } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth hook

const BackgroundAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #ff9a9e, #fad0c4, #fad0c4, #ff9a9e);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 15s ease infinite;
`;

const FormWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #ff6f61;
  margin-bottom: 1rem;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const SuccessScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  animation: ${keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `} 0.5s ease;
`;

const SuccessText = styled.h3`
  color: #4caf50;
`;

const LoginLink = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #007bff;
  text-align: center;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignUp = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Get currentUser from AuthContext
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard'); // Redirect to dashboard if user is already logged in
    }
  }, [currentUser, navigate]);

  const handleSignUp = async (values, { setSubmitting }) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, values.email, values.password);
      await updateProfile(user, {
        displayName: values.name // Set display name in Firebase user profile
      });
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    } catch (error) {
      alert(error.message);
    }
    setSubmitting(false);
  };

  return (
    <StyledContainer fluid>
      <FormWrapper>
        <IconWrapper>
          <FaLock />
        </IconWrapper>
        <h2>Sign Up</h2>
        <Formik
          initialValues={{ name: '', email: '', confirmEmail: '', password: '', confirmPassword: '' }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            confirmEmail: Yup.string().oneOf([Yup.ref('email'), null], 'Emails must match').required('Required'),
            password: Yup.string().required('Required').min(6, 'Password must be at least 6 characters'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required')
          })}
          onSubmit={handleSignUp}
        >
          <FormikForm>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Field name="name" type="text" className="form-control" />
              <ErrorMessage name="name" component={ErrorText} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage name="email" component={ErrorText} />
            </Form.Group>
            <Form.Group controlId="formConfirmEmail">
              <Form.Label>Confirm Email</Form.Label>
              <Field name="confirmEmail" type="email" className="form-control" />
              <ErrorMessage name="confirmEmail" component={ErrorText} />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component={ErrorText} />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Field name="confirmPassword" type="password" className="form-control" />
              <ErrorMessage name="confirmPassword" component={ErrorText} />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Sign Up
            </Button>
          </FormikForm>
        </Formik>
        <LoginLink>
          Already have an account? <Link to="/login">Login here</Link>
        </LoginLink>
      </FormWrapper>
      {showSuccess && (
        <SuccessScreen>
          <SuccessText>Registration Successful!</SuccessText>
          <p>Redirecting to profile...</p>
        </SuccessScreen>
      )}
    </StyledContainer>
  );
};

export default SignUp;
