import { collection, addDoc, Timestamp, getFirestore, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const colorChange = keyframes`
  0% { background-color: rgba(255, 0, 0, 0.1); }
  50% { background-color: rgba(0, 255, 0, 0.1); }
  100% { background-color: rgba(0, 0, 255, 0.1); }
`;

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  animation: ${colorChange} 10s infinite;
`;

const FormWrapper = styled.div`
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Icon = styled(FaCalendarAlt)`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #3498db;
`;

const SuccessMessage = styled.div`
  text-align: center;
  color: green;
  font-size: 20px;
  margin-top: 20px;
`;

const TickMark = styled.div`
  font-size: 50px;
  color: green;
  margin-top: 20px;
`;

const FeeNote = styled.p`
  color: red;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`;

const UnavailableMessage = styled.p`
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
`;

const OfficeHolidayNote = styled.p`
  color: #333;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

const fees = {
  astrology: {
    inPerson: {
      matchMaking: 2116,
      horoscope: 2516,
      fieldVisit: 6000,
      vaasthu: 6000,
      muhurtham: 2116,
    },
    call: {
      matchMaking: 2516,
      horoscope: 2816,
      fieldVisit: 6000,
      vaasthu: 6000,
      muhurtham: 2516,
    },
  },
  legal: {
    'Legal opinions': 5000,
    'Legal advice': 3500,
    'Verification of property documents': 4500,
    'Field visits': 6000,
    Others: 4000,
  },
};

const publicHolidays = [
  new Date('2024-08-15'), // Independence Day
  new Date('2024-08-26'),
  new Date('2024-09-07'),
  new Date('2024-10-02'), // Gandhi Jayanti
  new Date('2024-10-31'),
  new Date('2024-11-15'),
  new Date('2024-12-25'),
];

const secondSaturdays = [
  new Date('2024-07-13'),
  new Date('2024-08-10'),
  new Date('2024-09-14'),
  new Date('2024-10-12'),
  new Date('2024-11-09'),
  new Date('2024-12-14'),
];

const AppointmentForm = () => {
  const { currentUser } = useAuth(); // Retrieve currentUser from useAuth context
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    serviceType: 'astrology',
    subCategory: 'matchMaking',
    date: '',
    time: '',
    appointmentType: 'inPerson', // Added appointmentType field
  });

  const [availableTimes, setAvailableTimes] = useState([]);
  const [unavailableMessage, setUnavailableMessage] = useState('');
  const [currentFee, setCurrentFee] = useState(fees.astrology.inPerson.matchMaking);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const generateAndSetTimeSlots = async () => {
      const timeSlots = await generateTimeSlots();
      setAvailableTimes(timeSlots);
    };
    generateAndSetTimeSlots();
  }, [formData.serviceType, formData.date, formData.appointmentType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
    // Ensure 'time' field is updated correctly
    if (name === 'time') {
      setFormData({ ...formData, time: value });
    }
 
    if (name === 'subCategory' && formData.serviceType === 'astrology') {
      setCurrentFee(fees.astrology[formData.appointmentType][value]); // Updated to consider appointmentType
    } else if (name === 'appointmentType' && formData.serviceType === 'astrology') {
      setCurrentFee(fees.astrology[value][formData.subCategory]); // Update fee based on appointmentType and subCategory
    } else if (formData.serviceType === 'legal') {
      setCurrentFee(fees.legal[value]); // Update fee based on legal service sub-category
    }
  };

  const handleServiceTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      serviceType: value,
      subCategory: value === 'astrology' ? 'matchMaking' : 'Legal opinions', // Set default sub-category for legal
      appointmentType: value === 'astrology' ? 'inPerson' : '', // Set default appointmentType for astrology
    });

    if (value === 'astrology') {
      setCurrentFee(fees.astrology.inPerson.matchMaking);
    } else {
      setCurrentFee(fees.legal['Legal opinions']); // Set initial fee for legal services
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
  
    // Format date as day/month/year
    const selectedDate = new Date(formData.date);
    const formattedDate = `${selectedDate.getDate()}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`;
  
    try {
      const firestore = getFirestore();
  
      // Check if the selected time slot is already booked
      const appointmentsRef = collection(firestore, 'appointments');
      const q = query(
        appointmentsRef,
        where('date', '==', formattedDate),
        where('time', '==', formData.time),
        where('serviceType', '==', formData.serviceType)
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        setUnavailableMessage('This time slot is already booked. Please select a different time.');
        return;
      }
  
      const appointmentData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        serviceType: formData.serviceType,
        subCategory: formData.subCategory,
        date: formattedDate,
        time: formData.time,
        appointmentType: formData.appointmentType,
        userId: currentUser ? currentUser.uid : null, // Assign userId if currentUser is present
        createdAt: Timestamp.now(), // Use Firestore Timestamp for createdAt
        expireAt: Timestamp.fromMillis(Date.now() + 35 * 24 * 60 * 60 * 1000), // Example expiration time
        status: 'To Be Approved By Admin',
      };
  
      // Add appointment to Firestore
      const docRef = await addDoc(appointmentsRef, appointmentData);
      console.log('Appointment added with ID: ', docRef.id);
  
      // Set submission state and redirect
      setSubmitted(true);
      setTimeout(() => {
        navigate('/signup');
      }, 5000);
    } catch (error) {
      console.error('Error submitting appointment:', error);
    }
  };
  
  
  const generateTimeSlots = () => {
    const startTime = 10;
    const endTime = 18;
    const timeSlots = [];
  
    const currentDateTime = new Date();
    const selectedDate = new Date(formData.date);
  
    const isPublicHoliday = publicHolidays.some(
      (holiday) => holiday.toDateString() === selectedDate.toDateString()
    );
    const isSecondSaturday = secondSaturdays.some(
      (saturday) => saturday.toDateString() === selectedDate.toDateString()
    );
    const isSunday = selectedDate.getDay() === 0;
  
    const allowedDays = {
      astrology: [3, 6],
      legal: [1, 2, 4, 5],
    };
  
    const serviceDays = allowedDays[formData.serviceType];
  
    if (
      selectedDate &&
      (isPublicHoliday || isSecondSaturday || isSunday || !serviceDays.includes(selectedDate.getDay()))
    ) {
      const validDays = serviceDays
        .map((day) => {
          return day === 0
            ? 'Sunday'
            : day === 1
            ? 'Monday'
            : day === 2
            ? 'Tuesday'
            : day === 3
            ? 'Wednesday'
            : day === 4
            ? 'Thursday'
            : day === 5
            ? 'Friday'
            : 'Saturday';
        })
        .join(', ');
      setUnavailableMessage(
        `${formData.serviceType} service is only available on ${validDays}.`
      );
      return [];
    } else {
      setUnavailableMessage('');
    }
  
    for (let hour = startTime; hour < endTime; hour++) {
      const timeSlot = `${hour}:00`;
      if (selectedDate.toDateString() === currentDateTime.toDateString()) {
        const slotTime = new Date();
        slotTime.setHours(hour, 0, 0, 0);
        if (slotTime > currentDateTime) {
          timeSlots.push(timeSlot);
        }
      } else {
        timeSlots.push(timeSlot);
      }
    }
    return timeSlots;
  };
  
  
  return (
    <PageContainer>
      <FormWrapper>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Title>Book an Appointment</Title>
        {submitted ? (
          <>
            <SuccessMessage>Appointment Request Sent Successfully!</SuccessMessage>
            <SuccessMessage>Admin Will Check And Approve. Please Wait For Mail.</SuccessMessage>
            <TickMark>&#10004;</TickMark>
          </>
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="serviceType">Service Type:</label>
              <select
                id="serviceType"
                name="serviceType"
                value={formData.serviceType}
                onChange={handleServiceTypeChange}
              >
                <option value="astrology">Astrology</option>
                <option value="legal">Legal</option>
              </select>
            </FormGroup>
            {formData.serviceType === 'astrology' && (
              <>
                <FormGroup>
                  <label htmlFor="appointmentType">Appointment Type:</label>
                  <select
                    id="appointmentType"
                    name="appointmentType"
                    value={formData.appointmentType}
                    onChange={handleChange}
                    required
                  >
                    <option value="inPerson">In-Person</option>
                    <option value="call">Call</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="subCategory">Sub-Category:</label>
                  <select
                    id="subCategory"
                    name="subCategory"
                    value={formData.subCategory}
                    onChange={handleChange}
                    required
                  >
                    <option value="matchMaking">Marriage Matchmaking</option>
                    <option value="horoscope">Horoscope</option>
                    <option value="fieldVisit">Field Visit</option>
                    <option value="vaasthu">Vaasthu</option>
                    <option value="muhurtham">Muhurtham</option>
                  </select>
                </FormGroup>
              </>
            )}
            {formData.serviceType === 'legal' && (
              <FormGroup>
                <label htmlFor="subCategory">Sub-Category:</label>
                <select
                  id="subCategory"
                  name="subCategory"
                  value={formData.subCategory}
                  onChange={handleChange}
                  required
                >
                  <option value="Legal opinions">Legal opinions</option>
                  <option value="Legal advice">Legal advice</option>
                  <option value="Verification of property documents">Verification of property documents</option>
                  <option value="Field visits">Field visits</option>
                  <option value="Others">Others</option>
                </select>
              </FormGroup>
            )}
            <FormGroup>
              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
            <label htmlFor="time">Time</label>
            <select
              id="time"
              name="time"
              value={formData.time}
              onChange={handleChange}
              required
            >
              {availableTimes.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </FormGroup>
            {unavailableMessage && <UnavailableMessage>{unavailableMessage}</UnavailableMessage>}
            {currentFee && (
  <FeeNote>
    {formData.serviceType === 'legal' ? (
      'Please note: Fees will be decided at the time of appointment.'
    ) : (
      `Please note: The fee for this service is ₹${currentFee}`
    )}
  </FeeNote>
)}
            <SubmitButton type="submit">Submit</SubmitButton>
          </Form>
        )}
        <OfficeHolidayNote>Note: We are closed on Sundays, Second Saturdays, and Public Holidays.</OfficeHolidayNote>
      </FormWrapper>
    </PageContainer>
  );
};

export default AppointmentForm;
