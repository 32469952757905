// src/HomePage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const BackgroundAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #3494e6, #ec6ead);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 15s ease infinite;
`;

const ServiceCard = styled(Card)`
  margin-top: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const KnowMoreButton = styled(Link)`
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomePage = () => (
  <StyledContainer fluid>
    <h1 className="text-center mb-4">Welcome to A V Apparao Services</h1>
    <p className="text-center mb-4">
      We provide a wide range of legal and astrology services to cater to your needs. You can Signup and get many benifits like getting followed up with our updates,blogs, news and much more. Book an appointment right now to get started.
    </p>
    <Row className="justify-content-center">
      <Col xs={12} md={6}>
        <Row>
          <Col>
            <ServiceCard>
              <Card.Body>
                <Card.Title className="text-center">Legal Services</Card.Title>
                <Card.Text>
                  Explore our legal services including field visits, legal opinions, and advice. We ensure professional assistance for your legal matters.
                </Card.Text>
                <KnowMoreButton to="/legal-services">
                  Know More
                </KnowMoreButton>
              </Card.Body>
            </ServiceCard>
          </Col>
          <Col>
            <ServiceCard>
              <Card.Body>
                <Card.Title className="text-center">Astrology Services</Card.Title>
                <Card.Text>
                  Discover our astrology services such as matchmaking, horoscope reading, vaasthu consultations, and auspicious timings.
                </Card.Text>
                <KnowMoreButton to="/astrology-services">
                  Know More
                </KnowMoreButton>
              </Card.Body>
            </ServiceCard>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledContainer>
);

export default HomePage;
