import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const BackgroundAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledContainer = styled(Container)`
  background: linear-gradient(135deg, #ffecd2 0%, #fcb69f 100%);
  background-size: 200% 200%; 
  animation: ${BackgroundAnimation} 10s ease infinite;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
`;

const StyledHeading = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 2.5rem;
`;

const StyledText = styled.p`
  color: #333;
  text-align: center;
  font-size: 1.2rem;
`;

const StyledCard = styled(Card)`
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const AstrologyServicesPage = () => (
  <StyledContainer fluid>
    <Row className="justify-content-center">
      <Col xs={12} md={8}>
        <StyledHeading>
          <FontAwesomeIcon icon={faStar} style={{ marginRight: '10px' }} />
          A V APPARAO'S Astrology Services
        </StyledHeading>
        <StyledText>
          Discover our (Sree Manidweepeshwari Jaathaka Darshini) astrology services such as matchmaking, horoscope reading, vaasthu consultations, and auspicious timings.
        </StyledText>
        <Row className="mt-4">
          <Col xs={12}>
            <Row className="justify-content-center">
              <Col xs={12} sm={6} md={4} className="mb-4">
                <StyledCard>
                  <Card.Body>
                    <Card.Title className="text-center">Marriage Matchmaking</Card.Title>
                    <Card.Text>
                      Marriage matchmaking in astrology involves assessing the compatibility between two individuals based on their birth charts. Astrologers analyze planetary positions and their influences on aspects like personality, career, health, and more. This detailed examination helps determine whether a couple is well-suited for marriage, considering factors such as emotional harmony, financial stability, and longevity of the relationship.
                    </Card.Text>
                  </Card.Body>
                </StyledCard>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-4">
                <StyledCard>
                  <Card.Body>
                    <Card.Title className="text-center">Horoscope Reading</Card.Title>
                    <Card.Text>
                      Horoscope reading, a fundamental aspect of astrology, involves interpreting a person's birth chart or horoscope to reveal insights into their life path, personality traits, strengths, challenges, and potential opportunities. Astrologers analyze the positions of celestial bodies at the time of birth to provide guidance on various aspects of life, including career choices, relationships, health, and personal growth.
                    </Card.Text>
                  </Card.Body>
                </StyledCard>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-4">
                <StyledCard>
                  <Card.Body>
                    <Card.Title className="text-center">Vaasthu Consultations (Field Visit Or Online)</Card.Title>
                    <Card.Text>
                      Vaasthu, or Vastu Shastra, is an ancient Indian science that emphasizes harmonizing the energies in a physical space to promote health, wealth, and overall well-being. Astrologers specializing in Vaasthu conduct field visits or online consultations to assess the layout, orientation, and energies of homes or offices. They provide recommendations to align the space with natural forces, ensuring positive vibrations and fostering prosperity and peace for occupants.
                    </Card.Text>
                  </Card.Body>
                </StyledCard>
              </Col>
              <Col xs={12} className="mb-4">
                <StyledCard>
                  <Card.Body>
                    <Card.Title className="text-center">Auspicious Timings (Muhurtham)</Card.Title>
                    <Card.Text>
                      Auspicious timings, known as Muhurtham in astrology, refer to favorable periods determined through astrological calculations for conducting important events or ceremonies. These timings are chosen based on planetary alignments that are believed to enhance success and positive outcomes. Muhurthams are crucial for events such as weddings, housewarming ceremonies, for the birth of a baby, starting new ventures, or any significant life event where timing is considered auspicious for optimal results.
                    </Card.Text>
                  </Card.Body>
                </StyledCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledContainer>
);

export default AstrologyServicesPage;
