// AdminDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import styled from 'styled-components';

const AdminContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const SidePanel = styled.div`
  width: 250px;
  background-color: #343a40;
  color: white;
  padding: 20px;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
`;

const SidePanelHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const SidePanelItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SectionContainer = styled.div`
  margin-bottom: 20px;
`;

const SectionHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const LoadingText = styled.p`
  font-style: italic;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Input = styled.input`
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const AdminDashboard = () => {
  const { currentUser, logout, isAdmin } = useAuth(); // Destructure isAdmin from useAuth hook
  const [astrologyFees, setAstrologyFees] = useState({
    matchMaking: 1000,
    horoscope: 800,
    fieldVisit: 1200,
    vaasthu: 1500,
    muhurtham: 1800,
  });
  const [users, setUsers] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('appointments');

  useEffect(() => {
    if (isAdmin) { // Use isAdmin directly as a boolean, not as a function call
      fetchUsers();
      fetchAppointments();
    }
  }, []); // Empty dependency array means this effect runs once on mount

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/users');
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/appointments');
      setAppointments(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setLoading(false);
    }
  };

  const handleApproveAppointment = async (appointmentId) => {
    try {
      setLoading(true);
      await axios.put(`/admin/appointments/${appointmentId}/approve`);
      fetchAppointments();
    } catch (error) {
      console.error('Error approving appointment:', error);
      setLoading(false);
    }
  };

  const handleChangeFee = async (subcategory, newFee) => {
    try {
      setLoading(true);
      await axios.put('/admin/astrology-fees', { ...astrologyFees, [subcategory]: newFee });
      setAstrologyFees({ ...astrologyFees, [subcategory]: parseInt(newFee) });
    } catch (error) {
      console.error('Error updating astrology fees:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'appointments':
        return (
          <SectionContainer>
            <SectionHeader>Appointments</SectionHeader>
            {loading ? (
              <LoadingText>Loading appointments...</LoadingText>
            ) : (
              <List>
                {appointments.map((appointment) => (
                  <ListItem key={appointment.id}>
                    {appointment.name} - {appointment.date} - {appointment.time} - {appointment.status}
                    <Button onClick={() => handleApproveAppointment(appointment.id)}>Approve</Button>
                  </ListItem>
                ))}
              </List>
            )}
          </SectionContainer>
        );
      case 'fees':
        return (
          <SectionContainer>
            <SectionHeader>Update Astrology Fees</SectionHeader>
            <List>
              {Object.entries(astrologyFees).map(([subcategory, fee]) => (
                <ListItem key={subcategory}>
                  {subcategory}: <Input type="number" value={fee} onChange={(e) => handleChangeFee(subcategory, e.target.value)} />
                </ListItem>
              ))}
            </List>
            <Button onClick={() => setLoading(true)}>Submit Fees</Button>
          </SectionContainer>
        );
      case 'createDates':
        return (
          <SectionContainer>
            <SectionHeader>Create Appointment Dates</SectionHeader>
            <p>Functionality to release appointment dates will go here.</p>
          </SectionContainer>
        );
      case 'analytics':
        return (
          <SectionContainer>
            <SectionHeader>Analytics</SectionHeader>
            <p>Functionality to show user and payment analytics will go here.</p>
          </SectionContainer>
        );
      case 'users':
        return (
          <SectionContainer>
            <SectionHeader>Users</SectionHeader>
            {loading ? (
              <LoadingText>Loading users...</LoadingText>
            ) : (
              <List>
                {users.map((user) => (
                  <ListItem key={user.id}>
                    {user.name} - {user.email}
                  </ListItem>
                ))}
              </List>
            )}
          </SectionContainer>
        );
      case 'logout':
        return (
          <Button onClick={logout}>Logout</Button>
        );
      default:
        return null;
    }
  };

  return (
    <AdminContainer>
      <SidePanel>
        <SidePanelHeader>Admin Dashboard</SidePanelHeader>
        <SidePanelItem onClick={() => setActiveSection('appointments')}>Appointments</SidePanelItem>
        <SidePanelItem onClick={() => setActiveSection('fees')}>Update Fees</SidePanelItem>
        <SidePanelItem onClick={() => setActiveSection('createDates')}>Create Dates</SidePanelItem>
        <SidePanelItem onClick={() => setActiveSection('analytics')}>Analytics</SidePanelItem>
        <SidePanelItem onClick={() => setActiveSection('users')}>Users</SidePanelItem>
        <SidePanelItem onClick={() => setActiveSection('logout')}>Logout</SidePanelItem>
      </SidePanel>
      <Content>
        {renderSection()}
      </Content>
    </AdminContainer>
  );
};

export default AdminDashboard;
