import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

const BackgroundAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledContainer = styled(Container)`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  background-size: 100% 100%;
  animation: ${BackgroundAnimation} 10s ease infinite;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const StyledHeading = styled.h1`
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
`;

const CardWrapper = styled.div`
  margin-bottom: 20px;
`;

const ServiceCard = styled(Card)`
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ServiceCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ServiceTitle = styled(Card.Title)`
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
`;

const ServiceText = styled(Card.Text)`
  color: #666;
`;

const LegalServicesPage = () => (
  <StyledContainer fluid>
    <Row className="justify-content-center">
      <Col xs={12} md={8}>
        <StyledHeading>
          <FontAwesomeIcon icon={faBalanceScale} style={{ marginRight: '10px' }} />
          A V APPARAO'S Prajna Legal Services
        </StyledHeading>
        <p className="text-center text-white mb-4">
          Explore our legal services including legal advice, legal opinions, legal notices, and more. We ensure professional assistance for your legal matters.
        </p>
        <Row className="mt-4 justify-content-center">
          <Col xs={12} sm={6} md={4}>
            <CardWrapper>
              <ServiceCard className="service-card">
                <ServiceCardBody>
                  <ServiceTitle>Legal Advice</ServiceTitle>
                  <ServiceText>
                    Legal advice refers to guidance provided by a qualified legal professional on matters concerning the interpretation of laws, legal rights, and responsibilities. It helps individuals or organizations understand their legal position and make informed decisions regarding legal actions or compliance.
                  </ServiceText>
                </ServiceCardBody>
              </ServiceCard>
            </CardWrapper>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <CardWrapper>
              <ServiceCard className="service-card">
                <ServiceCardBody>
                  <ServiceTitle>Legal Opinions</ServiceTitle>
                  <ServiceText>
                    Legal opinions are formal documents issued by lawyers or legal experts that provide an evaluation and analysis of specific legal issues. They are often sought by clients to assess the legal implications of certain actions or decisions, providing clarity and guidance based on applicable laws and precedents.
                  </ServiceText>
                </ServiceCardBody>
              </ServiceCard>
            </CardWrapper>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <CardWrapper>
              <ServiceCard className="service-card">
                <ServiceCardBody>
                  <ServiceTitle>Legal Notices</ServiceTitle>
                  <ServiceText>
                    In India, A Legal Notice represents the initial step before initiating legal action, notifying the recipient to prepare for potential court proceedings or Functioning as a preemptive warning. A legal notice outlines conditions that the recipient must adhere to; failure to comply may result in legal repercussions.
                  </ServiceText>
                </ServiceCardBody>
              </ServiceCard>
            </CardWrapper>
          </Col>
        </Row>
        {/* Consolidated Card for Many Other Services */}
        <Row className="mt-4 justify-content-center">
          <Col xs={12} md={8}>
            <CardWrapper>
              <ServiceCard className="service-card">
                <ServiceCardBody>
                  <ServiceTitle>Many Other Services</ServiceTitle>
                  <ServiceText>
                    <ul>
                      <li>Field Visits</li>
                      <li>Verification of Documents</li>
                      <li>Affidavit</li>
                      <li>Attestation</li>
                      <li>Family Members Certificate</li>
                      <li>Title Search</li>
                      <li>Mediation And Conciliation</li>
                      <li>Legal Audits</li>
                      <li>Family Counseling</li>
                    </ul>
                  </ServiceText>
                </ServiceCardBody>
              </ServiceCard>
            </CardWrapper>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledContainer>
);

export default LegalServicesPage;
